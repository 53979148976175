/* PictureSelector.css */
.ml-options {
    display: flex;
    flex-direction: column;
  }
  
  .ml-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd; /* Default border color */
    border-radius: 5px;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .ml-option img {
    width: 50px; /* Adjust based on your image size */
    height: auto;
    margin-right: 10px;
  }
  
  .ml-option.selected {
    border-color: #007bff; /* Highlight border color */
    background-color: #e9f5ff; /* Highlight background color */
  }
  
  .ml-option:hover {
    background-color: #f1f1f1; /* Light hover effect */
  }
  
  /* PictureSelector.css */
.ml-options {
    display: flex;
    gap: 1rem;
}

.ml-option {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ml-option img {
    transition: transform 0.3s ease;
}

.ml-option.selected {
    border: 2px solid #007bff; /* Blue border for selected option */
    background-color: #e9ecef; /* Light gray background for selected option */
}

.ml-option:hover img {
    transform: scale(2.2); /* Zoom effect */
}

.ml-option:hover {
    transform: scale(1.05); /* Slightly scale up the option itself */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for emphasis */
}

.color-options {
    display: flex;
    gap: 1rem;
}

.color-option {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border 0.3s ease, transform 0.3s ease;
}

.color-option.selected {
    border: 4px solid #007bff; /* Blue border for selected color */
}

.color-option:hover {
    transform: scale(1.1); /* Slightly zoom in on hover */
}

.ml-option-img {
    width: 50px; /* Ensure consistent sizing as on the product screen */
    height: auto;
    margin-right: 10px;
}

.ml-option img {
    width: 50px; /* Adjust to the desired width */
    height: 50px; /* Adjust to the desired height */
    object-fit: contain; /* Ensures the image is scaled correctly without distortion */
    margin-right: 10px;
}

.center-text {
    text-align: center;
}
